import FuseUtils from "../core/utils";
import FuseLoading from "../core/FuseLoading";
import { Navigate } from "react-router-dom";
import settingsConfig from "../configs/settingsConfig";
// import userInterfaceConfigs from '../main/user-interface/UserInterfaceConfigs';
import LoginConfig from "../Components/Login/LoginConfig";
import Login2Config from "../Components/Login2/Login2Config";
// import SignUpConfig from '../main/sign-up/SignUpConfig';
// import SignOutConfig from '../main/sign-out/SignOutConfig';
import ComponentsConfig from "../Components/ComponentsConfig";
import RedirectToBrowser from "../Components/RedirectPage/RedirectToBrowser";
// import appsConfigs from '../main/apps/appsConfigs';
// import pagesConfigs from '../main/pages/pagesConfigs';
// import authRoleExamplesConfigs from '../main/auth/authRoleExamplesConfigs';
// import DocumentationConfig from '../main/documentation/DocumentationConfig';

const routeConfigs = [
  // ...appsConfigs,
  // ...dashboardsConfigs,
  // ...pagesConfigs,
  // ...authRoleExamplesConfigs,
  // ...userInterfaceConfigs,
  // DocumentationConfig,
  // SignOutConfig,
  ComponentsConfig,
  LoginConfig,
  // Login2Config,
  // SignUpConfig,
];
const routes = [
  ...FuseUtils.generateRoutesFromConfigs(
    routeConfigs,
    settingsConfig.defaultAuth
  ),
  {
    path: "/",
    element: <Navigate to="/welcome" />,
    auth: settingsConfig.defaultAuth,
  },
  {
    path: "loading",
    element: <FuseLoading />,
  },
  {
    path: "*",
    element: <Navigate to="/" />,
  },
  {
    path: "redirect",
    element: <RedirectToBrowser />,
  },
];

export default routes;
