import { useEffect } from "react";
import { Box, Typography, Container } from "@mui/material";
import { useSearchParams } from "react-router-dom";

const RedirectToBrowser = () => {
  const [searchParams] = useSearchParams();
  const sn = searchParams.get("sn");
  const redirectUrl = `https://easydrink-supplier.interconnext.co.th/?sn=${sn}`;

  const isLineApp = () => /line/i.test(navigator.userAgent);

  useEffect(() => {
    if (!isLineApp()) {
      // ถ้าเปิดจาก Chrome/Safari → redirect ไปเลย
      window.location.href = redirectUrl;
    }
  }, []);

  return (
    <Container
      maxWidth="sm"
      sx={{
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
      }}
    >
      <Box>
        <Typography variant="h3" gutterBottom>
          ⚠️
        </Typography>

        <Typography variant="h5" gutterBottom>
          เนื่องจาก LINE จำกัดการเปิดเบราว์เซอร์จากลิงก์
        </Typography>

        <Typography variant="h6" gutterBottom>
          กรุณากด <strong>“⋮” มุมขวาบนหรือขวาล่าง</strong> แล้วเลือก
          <strong> “เปิดในเบราว์เซอร์”</strong>
        </Typography>

        <Typography variant="h6">จากนั้นระบบจะทำงานตามปกติ</Typography>
      </Box>
    </Container>
  );
};

export default RedirectToBrowser;
